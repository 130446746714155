

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import {api_url} from "@/ayar";
import TableBaseComponent from "@/components/backoffice/table/TableBaseComponent.vue";
import {onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import { l }           from '@/core/helpers/lang';

export default ({
  name: "Belgeler",
  components: {
    TableBaseComponent
  },
  data: () => {
    return {
      url: api_url + 'belgeler',
      columns: [
        {
          title: 'Kazanç Tarihi',
          settings: {
            data: 'start_date',
            orderable: true
          }
        },
        {
          title: l('admin:languages:languages193'),
          settings: {
            data: 'paid',
            orderable: true,
            searchable: true
          }
        },
        {
          title: l('user:languages:languages249'),
          settings: {
            data: 'price',
            orderable: true
          }
        },
        {
          title: l('admin:genel:detay'),
          settings: {
            data: 'id',
            orderable: false,
            searchable: false
          }
        }
      ],
      columnDefs: [
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";

          },
          "targets": 0,
          className:'text-right align-bottom w-25'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";

          },
          "targets": 1,
          className:'text-center align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-muted fw-bold text-muted d-block fs-7' >" + data + "</div>";

          },
          "targets": 2,
          className:'text-right align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<a href='#' data-name='PageDetails' data-id='" + row['id'] + "' class='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1 routerLink' ><i class='far fa-eye'></i></a>";
          },
          "targets": 3,
        }
      ],

    }
  },
  methods: {
    drawCallback : function(obj)  {
          document.querySelectorAll<HTMLElement>('.routerLink').forEach(element=> {
            element.addEventListener("click", e => {
               e.preventDefault();
               obj.$router.push( { name: element.dataset.name, params : { id : element.dataset.id}} )
            });
          });
        }
  },

  setup() {
    onMounted(() => {
      setCurrentPageTitle(l('admin:transfer:Belgeler'));
    });
    return{l}
  }
});


